// GlobalContext.js

import React, { createContext, useContext, useReducer } from 'react';

// Inicializa el contexto
const GlobalContext = createContext();

// Define un reducer que manejará las acciones de actualización del estado global
const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'UPDATE_USER':
      return { ...state, user: { ...state.user, ...action.payload } };
    case 'SET_CUERPO':
      return { ...state, cuerpo: action.payload };
    case 'UPDATE_USER_FIELD':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'UPDATE_TEST_COMPLETED':
      // ... tu código existente
      return {
        ...state,
        user: {
          ...state.user,
          testCompleted: updatedTestCompleted,
        },
      };
    case 'UPDATE_PRACTICO_COMPLETED':
      // ... tu código existente
      return {
        ...state,
        user: {
          ...state.user,
          practicosCompleted: action.payload.practicosCompleted,
        },
      };
    case 'UPDATE_RETOS_COMPLETADOS':
      return {
        ...state,
        user: {
          ...state.user,
          retosCompletados: Object.keys(action.payload).filter(id => action.payload[id]),
        },
      };
    case 'UPDATE_RETOS_CANJEADOS':
      return {
        ...state,
        user: {
          ...state.user,
          retosCanjeados: action.payload,
        },
      };
    case 'UPDATE_HIGHLIGHTED_MATERIALS':
      // ... tu código existente
      return {
        ...state,
        user: {
          ...state.user,
          highlightedMaterials: updatedHighlightedMaterials,
        },
      };
    // Añadimos el nuevo caso
    case 'UPDATE_BANNER':
      return {
        ...state,
        user: {
          ...state.user,
          bannerActive: action.payload,
        },
      };
    default:
      return state;
  }
};

// Define el componente proveedor del contexto
export const GlobalProvider = ({ children }) => {
  const initialState = {
    user: {
      bannerActive: false, // Establecemos en false
      retosCompletados: [],
      retosCanjeados: [],
      highlightedMaterials: [], // Añadir campo inicializado
    },
    cuerpo: null,
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Hook personalizado para utilizar el contexto
export const useGlobal = () => useContext(GlobalContext);
