import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/users';

// const baseUrl = 'http://localhost:3001/api/users';


const UserService = {
  register: async (phoneNumber, password, repeatPassword, email, userName) => {
    try {
      const response = await axios.post(`${baseUrl}/register`, {
        phoneNumber,
        password,
        repeatPassword,
        email, 
        userName
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al registrar usuario';
    }
  },

  updateBannerActive: async (userId, bannerActive) => {
    try {
      const response = await axios.patch(`${baseUrl}/banner-active/${userId}`, { bannerActive });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al actualizar bannerActive del usuario');
    }
  },
  

  addLevelToUser: async (userId, levelId) => {
    try {
      const response = await axios.post(`${baseUrl}/${userId}/addLevel`, { levelId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al agregar el nivel al usuario');
    }
  },

  addNotePadToUser: async (userId, notePadId) => {
    try {
      const response = await axios.post(`${baseUrl}/${userId}/notePads`, { notePadId });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || 'Error al agregar NotePad a usuario');
    }
  },



  updateSubscriptionCategory: async (userId, subscriptionCategory) => {
    try {
      const response = await axios.patch(`${baseUrl}/${userId}/subscriptionCategory`, { subscriptionCategory });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al actualizar la categoría de suscripción del usuario');
    }
  },

  addOrUpdateTestCompleted: async (userId, testId, respuestas, preguntasData, scoreP, categoryId) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/addOrUpdateTestCompleted`, {
        testId,
        respuestas,
        preguntasData,
        score: scoreP,
        categoryId
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar test completado';
    }
  },

  incrementIndexReto: async (userId) => {
    try {
      const response = await axios.patch(`${baseUrl}/${userId}/incrementIndexReto`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al incrementar el índice del reto');
    }
  },

  updateUserLevel: async (userId, levelName) => {
    try {
      const response = await axios.patch(`${baseUrl}/${userId}/level`, { levelName });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al actualizar el nivel del usuario');
    }
  },

  confirmRegistration: async (verificationCode) => {
    try {
      const response = await axios.post(`${baseUrl}/confirm-registration/${verificationCode}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al confirmar registro';
    }
  },

  addCreditosCorreccion: async (userId, creditos) => {
    try {
      const response = await axios.patch(`${baseUrl}/${userId}/addCreditosCorreccion`, { creditos });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || 'Error al añadir créditos de corrección');
    }
  },

  login: async (phoneNumber, password) => {
    try {
      const response = await axios.post(`${baseUrl}/login`, {
        phoneNumber,
        password
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al iniciar sesión';
    }
  },

  resendSMSCode: async (phoneNumber, verificationCode) => {
    try {
      const response = await axios.post(`${baseUrl}/resent-smsCode`, { phoneNumber, verificationCode });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al reenviar código de verificación';
    }
  },

  completeReto: async (userId, retoId) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/completeReto/${retoId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Error al completar el reto');
    }
  },

  canjeReto: async (userId, retoId) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/canjeReto`, { retoId });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al canjear el reto';
    }
  },

  // Método para obtener los retos completados por un usuario
  getRetosCompletados: async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/${userId}/retosCompletados`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Error al obtener los retos completados');
    }
  },

  addOrUpdateTestCompleted: async (userId, testId, score, testData, categoryId) => {
    try {
        const response = await axios.put(`${baseUrl}/${userId}/addOrUpdateTestCompleted`, {
            testId,
            score,
            testContent: testData,  // Asegúrate que esto incluya toda la data del test necesaria para el PDF
            categoryId: categoryId
        });
        return response.data;
    } catch (error) {
        throw error.response.data.error || 'Error al actualizar los tests completados del usuario';
    }
},

getUserCompletedTestsByCategory: async (userId, categoryId) => {
  try {
    const response = await axios.get(`${baseUrl}/${userId}/${categoryId}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || 'Error al obtener los tests completados';
  }
},

  getCurrentUser: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener datos del usuario';
    }
  },

  confirmReferral: async (userId, referralCode) => {
    try {
      const response = await axios.post(`${baseUrl}/confirm-referral/${referralCode}`, { userId });
      return response.data; // Devuelve la respuesta del servidor
    } catch (error) {
      // Lanza un error con el mensaje del servidor o un mensaje por defecto
      throw new Error(error.response?.data?.error || 'Error al confirmar el referido');
    }
  },
  
  checkReferral: async (referralId, userId) => {
    try {
      const response = await axios.post(`${baseUrl}/check-referral`, { referralId, userId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al verificar y redimir el referido');
    }
  },
  

  getUserById: async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener datos del usuario';
    }
  },

  getAllUsers: async() => {
    try {
      const response = await axios.get(`${baseUrl}/`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener datos del usuario';
    }
  },
  

  updateEmail: async (userId, email) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/email`, { email });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar el email del usuario';
    }
  },

  getUsersByLevel: async(levelId) => {
    try {
      const response = await axios.get(`${baseUrl}/level/${levelId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener usuarios por nivel';
    }
  },
  
  updateCuerpo: async (userId, cuerpoId) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/cuerpo`, { cuerpo: cuerpoId });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar el cuerpo del usuario';
    }
  },
  

  updateUser: async (userId, newData) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}`, {newData});
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar datos del usuario';
    }
  },

  updateUserName: async (userId, userName) => {
    try {
      const response = await axios.put(`${baseUrl}/${userId}/username`, { userName });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar el nombre de usuario';
    }
  },

  addCuerpoId: async (id, cuerpoId) => {
    try {
      const response = await axios.put(`${baseUrl}/${id}`, {cuerpo: cuerpoId});
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar datos del usuario';
    }
  },

  resetPassword: async (email) => {
    try {
      const response = await axios.post(`${baseUrl}/recovery-password`, { email });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al solicitar restablecimiento de contraseña';
    }
  },

  resetPasswordWithCode: async (code, newPassword) => {
    try {
      const response = await axios.post(`${baseUrl}/reset-password`, { code, newPassword });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || 'Error al restablecer contraseña');
    }
  },

  deleteUser: async () => {
    try {
      const response = await axios.delete(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al eliminar usuario';
    }
  }, 

  saveHighlightedMaterial: async (userId, materialId, content) => {
    try {
      const response = await axios.post(`${baseUrl}/${userId}/highlightedMaterials`, { materialId, content });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || 'Error al guardar el material resaltado');
    }
  },

  fetchHighlightedMaterial: async (userId, materialId) => {
    try {
      const response = await axios.get(`${baseUrl}/${userId}/highlightedMaterials/${materialId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error || 'Error al obtener el material resaltado');
    }
  }
};

export default UserService;
